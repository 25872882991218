<template>
  <v-sheet flat color="primary" class="pa-1 mr-5">
    <v-row dense>
      <v-col class="d-flex justify-end">
        Submitted: {{ created }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="d-flex justify-end">
        Modified: {{ modified }}
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "QuoteDetailsDate",
  props: {
    created: {
      type: String,
      default: null,
    },
    modified: {
      type: String,
      default: null,
    },
  }
};
</script>
